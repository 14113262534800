export const InteractionTypeConsts = {
  MOUSE_CLICK_INTERACTION: 'MOUSE_CLICK_INTERACTION' as const,
  MOUSE_HOVER_INTERACTION: 'MOUSE_HOVER_INTERACTION' as const,
  MOUSE_MOVE_INTERACTION: 'MOUSE_MOVE_INTERACTION' as const,
  SCROLL_INTO_VIEW_INTERACTION: 'SCROLL_INTO_VIEW_INTERACTION' as const,
  SCROLLING_IN_VIEW_INTERACTION: 'SCROLLING_IN_VIEW_INTERACTION' as const,
  MOUSE_MOVE_IN_VIEWPORT_INTERACTION:
    'MOUSE_MOVE_IN_VIEWPORT_INTERACTION' as const,
  PAGE_IS_SCROLLING_INTERACTION: 'PAGE_IS_SCROLLING_INTERACTION' as const,
  PAGE_LOAD_INTERACTION: 'PAGE_LOAD_INTERACTION' as const,
  PAGE_SCROLLED_INTERACTION: 'PAGE_SCROLLED_INTERACTION' as const,
  NAVBAR_INTERACTION: 'NAVBAR_INTERACTION' as const,
  DROPDOWN_INTERACTION: 'DROPDOWN_INTERACTION' as const,
  ECOMMERCE_CART_INTERACTION: 'ECOMMERCE_CART_INTERACTION' as const,
  TAB_INTERACTION: 'TAB_INTERACTION' as const,
  SLIDER_INTERACTION: 'SLIDER_INTERACTION' as const,
} as const;
