import {IX2EngineActionTypes} from '@packages/systems/ix2/shared-constants';
const {
  IX2_SESSION_INITIALIZED,
  IX2_SESSION_STARTED,
  IX2_TEST_FRAME_RENDERED,
  IX2_SESSION_STOPPED,
  IX2_EVENT_LISTENER_ADDED,
  IX2_EVENT_STATE_CHANGED,
  IX2_ANIMATION_FRAME_CHANGED,
  IX2_ACTION_LIST_PLAYBACK_CHANGED,
  IX2_VIEWPORT_WIDTH_CHANGED,
  IX2_MEDIA_QUERIES_DEFINED,
} = IX2EngineActionTypes;
import {set, setIn, addLast, merge} from 'timm';
import {
  actionListPlaybackChangedPayload,
  animationFrameChangedPayload,
  eventListenerAddedPayload,
  eventStateChangedPayload,
  sessionInitializedPayload,
  testFrameRenderedPayload,
  viewportWidthChangedPayload,
} from '../actions/IX2EngineActions';

const initialState = {
  active: false,
  tick: 0,
  eventListeners: [],
  eventState: {},
  playbackState: {},
  viewportWidth: 0,
  mediaQueryKey: null,
  hasBoundaryNodes: false,
  hasDefinedMediaQueries: false,
  reducedMotion: false,
};

const TEST_FRAME_STEPS_SIZE = 20;

type ixSessionReducerState = {
  active: boolean;
  tick: number;
  eventListeners: any[];
  eventState: Record<string, any>;
  playbackState: Record<string, any>;
  viewportWidth: number;
  mediaQueryKey: null;
  hasBoundaryNodes: boolean;
  hasDefinedMediaQueries: boolean;
  reducedMotion: boolean;
};

type ixSessionReducerAction =
  | {
      type: typeof IX2_SESSION_INITIALIZED;
      payload: sessionInitializedPayload;
    }
  | {
      type: typeof IX2_SESSION_STARTED;
    }
  | {
      type: typeof IX2_TEST_FRAME_RENDERED;
      payload: testFrameRenderedPayload;
    }
  | {
      type: typeof IX2_SESSION_STOPPED;
    }
  | {
      type: typeof IX2_ANIMATION_FRAME_CHANGED;
      payload: animationFrameChangedPayload;
    }
  | {
      type: typeof IX2_EVENT_LISTENER_ADDED;
      payload: eventListenerAddedPayload;
    }
  | {
      type: typeof IX2_EVENT_STATE_CHANGED;
      payload: eventStateChangedPayload;
    }
  | {
      type: typeof IX2_ACTION_LIST_PLAYBACK_CHANGED;
      payload: actionListPlaybackChangedPayload;
    }
  | {
      type: typeof IX2_VIEWPORT_WIDTH_CHANGED;
      payload: viewportWidthChangedPayload;
    }
  | {
      type: typeof IX2_MEDIA_QUERIES_DEFINED;
    };

export const ixSession = (
  state: ixSessionReducerState = initialState,
  action: ixSessionReducerAction
): ixSessionReducerState => {
  switch (action.type) {
    case IX2_SESSION_INITIALIZED: {
      const {hasBoundaryNodes, reducedMotion} = action.payload;
      return merge(state, {
        hasBoundaryNodes,
        reducedMotion,
      }) as ixSessionReducerState;
    }
    case IX2_SESSION_STARTED: {
      return set(state, 'active', true);
    }
    case IX2_TEST_FRAME_RENDERED: {
      const {
        payload: {step = TEST_FRAME_STEPS_SIZE},
      } = action;
      return set(state, 'tick', state.tick + step);
    }
    case IX2_SESSION_STOPPED: {
      return initialState;
    }
    case IX2_ANIMATION_FRAME_CHANGED: {
      const {
        payload: {now},
      } = action;
      return set(state, 'tick', now);
    }
    case IX2_EVENT_LISTENER_ADDED: {
      const eventListeners = addLast(state.eventListeners, action.payload);
      return set(state, 'eventListeners', eventListeners);
    }
    case IX2_EVENT_STATE_CHANGED: {
      const {stateKey, newState} = action.payload;
      return setIn(state, ['eventState', stateKey], newState);
    }
    case IX2_ACTION_LIST_PLAYBACK_CHANGED: {
      const {actionListId, isPlaying} = action.payload;
      return setIn(state, ['playbackState', actionListId], isPlaying);
    }
    case IX2_VIEWPORT_WIDTH_CHANGED: {
      const {width, mediaQueries} = action.payload;
      const mediaQueryCount = mediaQueries.length;
      let mediaQueryKey = null;
      for (let i = 0; i < mediaQueryCount; i++) {
        const {key, min, max} = mediaQueries[i];
        if (width >= min && width <= max) {
          mediaQueryKey = key;
          break;
        }
      }
      return merge(state, {
        viewportWidth: width,
        mediaQueryKey,
      }) as ixSessionReducerState;
    }
    case IX2_MEDIA_QUERIES_DEFINED: {
      return set(state, 'hasDefinedMediaQueries', true);
    }
    default: {
      return state;
    }
  }
};
