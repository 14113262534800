/* eslint-env browser */
import find from 'lodash/find';

export const IS_BROWSER_ENV = typeof window !== 'undefined';

export const withBrowser = (fn: any, fallback: any) => {
  if (IS_BROWSER_ENV) {
    return fn();
  }
  return fallback;
};

// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
export const ELEMENT_MATCHES = withBrowser(() => {
  return find(
    [
      'matches',
      'matchesSelector',
      'mozMatchesSelector',
      'msMatchesSelector',
      'oMatchesSelector',
      'webkitMatchesSelector',
    ],
    (key) => key in Element.prototype
  );
});

export const FLEX_PREFIXED = withBrowser(() => {
  const el = document.createElement('i');
  const values = [
    'flex',
    '-webkit-flex',
    '-ms-flexbox',
    '-moz-box',
    '-webkit-box',
  ];
  const none = '';
  try {
    const {length} = values;
    for (let i = 0; i < length; i++) {
      const value = values[i];
      // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
      el.style.display = value;
      if (el.style.display === value) {
        return value;
      }
    }
    return none;
  } catch (err: any) {
    return none;
  }
}, 'flex');

export const TRANSFORM_PREFIXED = withBrowser(() => {
  const el = document.createElement('i');
  if (el.style.transform == null) {
    const prefixes = ['Webkit', 'Moz', 'ms'];
    const suffix = 'Transform';
    const {length} = prefixes;
    for (let i = 0; i < length; i++) {
      const prop = prefixes[i] + suffix;
      // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
      if (el.style[prop] !== undefined) {
        return prop;
      }
    }
  }
  return 'transform';
}, 'transform');

const TRANSFORM_PREFIX = TRANSFORM_PREFIXED.split('transform')[0];

export const TRANSFORM_STYLE_PREFIXED = TRANSFORM_PREFIX
  ? TRANSFORM_PREFIX + 'TransformStyle'
  : 'transformStyle';
