import {
  ActionTypeConsts,
  IX2EngineActionTypes,
} from '@packages/systems/ix2/shared-constants';

import {IX2VanillaUtils} from '@packages/systems/ix2/shared';

import type {
  ActionListId,
  ActionId,
  EventId,
  ContinuousParameterGroupId,
  ActionTypeIdType,
  ActionListsType,
  EventsType,
  EventType,
} from '@packages/systems/ix2/types-core';
import {BreakpointID} from '@packages/systems/style/types';

const {
  IX2_RAW_DATA_IMPORTED,
  IX2_SESSION_INITIALIZED,
  IX2_SESSION_STARTED,
  IX2_SESSION_STOPPED,
  IX2_PREVIEW_REQUESTED,
  IX2_PLAYBACK_REQUESTED,
  IX2_STOP_REQUESTED,
  IX2_CLEAR_REQUESTED,
  IX2_EVENT_LISTENER_ADDED,
  IX2_TEST_FRAME_RENDERED,
  IX2_EVENT_STATE_CHANGED,
  IX2_ANIMATION_FRAME_CHANGED,
  IX2_PARAMETER_CHANGED,
  IX2_INSTANCE_ADDED,
  IX2_INSTANCE_STARTED,
  IX2_INSTANCE_REMOVED,
  IX2_ELEMENT_STATE_CHANGED,
  IX2_ACTION_LIST_PLAYBACK_CHANGED,
  IX2_VIEWPORT_WIDTH_CHANGED,
  IX2_MEDIA_QUERIES_DEFINED,
} = IX2EngineActionTypes;

const {reifyState} = IX2VanillaUtils;

export type IX2RawData = {
  events: EventsType;
  actionLists: ActionListsType;
  site: {mediaQueries: {key: BreakpointID; min: number; max: number}[]};
};

export type rawDataImportedPayload = {
  ixData: {
    events: EventsType | undefined;
    actionLists: ActionListsType | undefined;
    eventTypeMap: {[k in EventType['eventTypeId']]: {[key: string]: EventType}};
    mediaQueries: IX2RawData['site']['mediaQueries'];
    mediaQueryKeys: BreakpointID[];
  };
};

export const rawDataImported = (rawData: IX2RawData) => ({
  type: IX2_RAW_DATA_IMPORTED,
  payload: {
    ...reifyState(rawData),
  },
});

export type sessionInitializedPayload = {
  hasBoundaryNodes: boolean;
  reducedMotion: boolean;
};

export const sessionInitialized = ({
  hasBoundaryNodes,
  reducedMotion,
}: sessionInitializedPayload) => ({
  type: IX2_SESSION_INITIALIZED,
  payload: {
    hasBoundaryNodes,
    reducedMotion,
  },
});

export const sessionStarted = () => ({
  type: IX2_SESSION_STARTED,
});

export const sessionStopped = () => ({
  type: IX2_SESSION_STOPPED,
});

export type previewRequestedPayload = {
  rawData: IX2RawData;
  defer: boolean;
};

export const previewRequested = ({
  rawData,
  defer,
}: previewRequestedPayload) => ({
  type: IX2_PREVIEW_REQUESTED,
  payload: {
    defer,
    rawData,
  },
});

export type playbackRequestedPayload = {
  actionTypeId:
    | typeof ActionTypeConsts.GENERAL_START_ACTION
    | typeof ActionTypeConsts.GENERAL_CONTINUOUS_ACTION;
  actionListId: ActionListId;
  actionItemId: ActionId;
  eventId: EventId;
  allowEvents: boolean;
  immediate: boolean;
  testManual?: boolean;
  verbose: boolean;
  rawData: IX2RawData;
};

export const playbackRequested = ({
  actionTypeId = ActionTypeConsts.GENERAL_START_ACTION,
  actionListId,
  actionItemId,
  eventId,
  allowEvents,
  immediate,
  testManual,
  verbose,
  rawData,
}: playbackRequestedPayload) => ({
  type: IX2_PLAYBACK_REQUESTED,
  payload: {
    actionTypeId,
    actionListId,
    actionItemId,
    testManual,
    eventId,
    allowEvents,
    immediate,
    verbose,
    rawData,
  },
});

export type stopRequestedPayload = {
  actionListId: ActionListId;
};

export const stopRequested = (
  actionListId: stopRequestedPayload['actionListId']
) => ({
  type: IX2_STOP_REQUESTED,
  payload: {
    actionListId,
  },
});

export const clearRequested = () => ({
  type: IX2_CLEAR_REQUESTED,
});

export type eventListenerAddedPayload = {
  target: any;
  listenerParams: any;
};

export const eventListenerAdded = (
  target: eventListenerAddedPayload['target'],
  listenerParams: eventListenerAddedPayload['listenerParams']
) => ({
  type: IX2_EVENT_LISTENER_ADDED,
  payload: {
    target,
    listenerParams,
  },
});

export type testFrameRenderedPayload = {
  step?: number;
};

export const testFrameRendered = (
  step: testFrameRenderedPayload['step'] = 1
) => ({
  type: IX2_TEST_FRAME_RENDERED,
  payload: {
    step,
  },
});

export type eventStateChangedPayload = {
  stateKey: any;
  newState: any;
};

export const eventStateChanged = (
  stateKey: eventStateChangedPayload['stateKey'],
  newState: eventStateChangedPayload['newState']
) => ({
  type: IX2_EVENT_STATE_CHANGED,
  payload: {
    stateKey,
    newState,
  },
});

export type animationFrameChangedPayload = {
  now: number;
  parameters: any;
};

export const animationFrameChanged = (
  now: animationFrameChangedPayload['now'],
  parameters: animationFrameChangedPayload['parameters']
) => ({
  type: IX2_ANIMATION_FRAME_CHANGED,
  payload: {
    now,
    parameters,
  },
});

export const parameterChanged = (
  key: ContinuousParameterGroupId,
  value: number
) => ({
  type: IX2_PARAMETER_CHANGED,
  payload: {
    key,
    value,
  },
});

export const instanceAdded = (options: any) => ({
  type: IX2_INSTANCE_ADDED,
  payload: {
    ...options,
  },
});

export const instanceStarted = (instanceId: string, time: number) => ({
  type: IX2_INSTANCE_STARTED,
  payload: {
    instanceId,
    time,
  },
});

export const instanceRemoved = (instanceId: string) => ({
  type: IX2_INSTANCE_REMOVED,
  payload: {
    instanceId,
  },
});

export const elementStateChanged = (
  elementId: any,
  actionTypeId: ActionTypeIdType,
  current: any,
  actionItem: any
) => ({
  type: IX2_ELEMENT_STATE_CHANGED,
  payload: {
    elementId,
    actionTypeId,
    current,
    actionItem,
  },
});

export type actionListPlaybackChangedPayload = {
  actionListId: ActionListId;
  isPlaying: boolean;
};

export const actionListPlaybackChanged = ({
  actionListId,
  isPlaying,
}: actionListPlaybackChangedPayload) => ({
  type: IX2_ACTION_LIST_PLAYBACK_CHANGED,
  payload: {
    actionListId,
    isPlaying,
  },
});

export type viewportWidthChangedPayload = {
  width: number;
  mediaQueries: any;
};

export const viewportWidthChanged = ({
  width,
  mediaQueries,
}: viewportWidthChangedPayload) => ({
  type: IX2_VIEWPORT_WIDTH_CHANGED,
  payload: {
    width,
    mediaQueries,
  },
});

export const mediaQueriesDefined = () => ({
  type: IX2_MEDIA_QUERIES_DEFINED,
});
