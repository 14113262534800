export const ActionTypeConsts = {
  TRANSFORM_MOVE: 'TRANSFORM_MOVE' as const,
  TRANSFORM_SCALE: 'TRANSFORM_SCALE' as const,
  TRANSFORM_ROTATE: 'TRANSFORM_ROTATE' as const,
  TRANSFORM_SKEW: 'TRANSFORM_SKEW' as const,
  STYLE_OPACITY: 'STYLE_OPACITY' as const,
  STYLE_SIZE: 'STYLE_SIZE' as const,
  STYLE_FILTER: 'STYLE_FILTER' as const,
  STYLE_FONT_VARIATION: 'STYLE_FONT_VARIATION' as const,
  STYLE_BACKGROUND_COLOR: 'STYLE_BACKGROUND_COLOR' as const,
  STYLE_BORDER: 'STYLE_BORDER' as const,
  STYLE_TEXT_COLOR: 'STYLE_TEXT_COLOR' as const,
  OBJECT_VALUE: 'OBJECT_VALUE' as const,
  PLUGIN_LOTTIE: 'PLUGIN_LOTTIE' as const,
  PLUGIN_SPLINE: 'PLUGIN_SPLINE' as const,
  PLUGIN_RIVE: 'PLUGIN_RIVE' as const,
  PLUGIN_VARIABLE: 'PLUGIN_VARIABLE' as const,
  GENERAL_DISPLAY: 'GENERAL_DISPLAY' as const,
  GENERAL_START_ACTION: 'GENERAL_START_ACTION' as const,
  GENERAL_CONTINUOUS_ACTION: 'GENERAL_CONTINUOUS_ACTION' as const,

  // TODO: Clean these up below because they're not used at this time
  GENERAL_COMBO_CLASS: 'GENERAL_COMBO_CLASS' as const,
  GENERAL_STOP_ACTION: 'GENERAL_STOP_ACTION' as const,
  GENERAL_LOOP: 'GENERAL_LOOP' as const,
  STYLE_BOX_SHADOW: 'STYLE_BOX_SHADOW' as const,
} as const;

export type ActionTypeIdType = keyof typeof ActionTypeConsts;

export const ActionAppliesTo = {
  ELEMENT: 'ELEMENT' as const,
  ELEMENT_CLASS: 'ELEMENT_CLASS' as const,
  TRIGGER_ELEMENT: 'TRIGGER_ELEMENT' as const,
} as const;
