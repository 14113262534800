// Array.includes needed for IE11 @packages/systems/ix2/shared-utils/quick-effects
import 'core-js/features/array/includes';

import {createStore} from 'redux';
import reducer from './reducers/IX2Reducer';
import {
  startEngine,
  stopEngine,
  observeRequests,
} from './logic/IX2VanillaEngine';
import * as actions from './actions/IX2EngineActions';
import {
  type IX2RawData,
  type rawDataImportedPayload,
} from './actions/IX2EngineActions';

const store = createStore(reducer);

function setEnv(env: (arg1?: string | null | undefined) => boolean) {
  if (env()) {
    observeRequests(store);
  }
}

function init(rawData: IX2RawData) {
  destroy();

  startEngine({store, rawData, allowEvents: true});
}

function destroy() {
  stopEngine(store);
}

export {
  setEnv,
  init,
  destroy,
  store,
  actions,
  type IX2RawData,
  type rawDataImportedPayload,
};
