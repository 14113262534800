/* eslint-env browser */

// Importing directly to avoid importing the entire shared-utils package.
// eslint-disable-next-line webflow/package-boundaries
import {normalizeColor} from '../shared-utils/normalizeColor';

export const getPluginConfig = (actionItemConfig, key) => {
  return actionItemConfig.value[key];
};

export const getPluginDuration = () => {
  // Not used by this plugin
  return null;
};

export const getPluginOrigin = (refState, actionItem) => {
  if (refState) {
    return refState;
  }

  // Determine which props we care about in the destination
  const destination = actionItem.config.value;

  // Look up root variable to parse origin values
  const objectId = actionItem.config.target.objectId;
  const computedValue = getComputedStyle(
    document.documentElement
  ).getPropertyValue(objectId);

  if (destination.size != null) {
    return {size: parseInt(computedValue, 10)};
  }

  if (destination.unit === '%' || destination.unit === '-') {
    return {size: parseFloat(computedValue)};
  }

  if (
    destination.red != null &&
    destination.green != null &&
    destination.blue != null
  ) {
    return normalizeColor(computedValue);
  }
};

export const getPluginDestination = (actionItemConfig) => {
  return actionItemConfig.value;
};

export const createPluginInstance = () => {
  // Not used by this plugin
  return null;
};

const variableHandlers = {
  color: {
    match: ({red, green, blue, alpha}) =>
      [red, green, blue, alpha].every((x) => x != null),
    getValue: ({red, green, blue, alpha}) =>
      `rgba(${red}, ${green}, ${blue}, ${alpha})`,
  },

  // Size, Percentage, and Unitless variables.
  size: {
    match: ({size}) => size != null,
    getValue: ({size}, unit) => {
      switch (unit) {
        case '-':
          return size;
        default:
          return `${size}${unit}`;
      }
    },
  },
};

export const renderPlugin = (_, refState, actionItem) => {
  const {
    target: {objectId},
    value: {unit},
  } = actionItem.config;
  const props = refState.PLUGIN_VARIABLE;

  const handler = Object.values(variableHandlers).find((h) =>
    h.match(props, unit)
  );

  if (handler) {
    document.documentElement.style.setProperty(
      objectId,
      handler.getValue(props, unit)
    );
  }
};

export const clearPlugin = (ref, actionItem) => {
  const objectId = actionItem.config.target.objectId;
  document.documentElement.style.removeProperty(objectId);
};
