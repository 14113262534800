import {Store, combineReducers, type Reducer} from 'redux';

import {ixData, type ixDataReducerState} from './IX2DataReducer';
import {ixRequest} from './IX2RequestReducer';
import {ixSession} from './IX2SessionReducer';
import {IX2ElementsReducer} from '@packages/systems/ix2/shared';
const {ixElements} = IX2ElementsReducer;
import {ixInstances} from './IX2InstancesReducer';
import {ixParameters} from './IX2ParametersReducer';

export type IX2EngineReducerStateShape = {
  ixData: ReturnType<typeof ixData>;
  ixParameters: ReturnType<typeof ixParameters>;
  ixSession: ReturnType<typeof ixSession>;
  ixElements: ReturnType<typeof ixElements>;
  ixInstances: ReturnType<typeof ixInstances>;
  ixRequest: ReturnType<typeof ixRequest>;
};

export default combineReducers<IX2EngineReducerStateShape>({
  ixData: ixData as Reducer<ixDataReducerState>,
  ixRequest,
  ixSession,
  ixElements,
  ixInstances,
  ixParameters,
});

export type IX2EngineReducerStore = Store<IX2EngineReducerStateShape>;
