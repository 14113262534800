import {IX2EngineActionTypes} from '@packages/systems/ix2/shared-constants';
import {rawDataImportedPayload} from '../actions/IX2EngineActions';
const {IX2_RAW_DATA_IMPORTED, IX2_SESSION_STOPPED, IX2_PARAMETER_CHANGED} =
  IX2EngineActionTypes;

type ixParametersAction =
  | {
      type: typeof IX2_RAW_DATA_IMPORTED;
      payload: rawDataImportedPayload;
    }
  | {
      type: typeof IX2_SESSION_STOPPED;
    }
  | {
      type: typeof IX2_PARAMETER_CHANGED;
      payload: {key: string; value: number};
    };

type ixParametersReducerState = Record<string, number>;

// prettier-ignore
export const ixParameters = (
  state: ixParametersReducerState = {/*mutable flat state*/},
  action: ixParametersAction
): ixParametersReducerState => {
  switch (action.type) {
    case IX2_RAW_DATA_IMPORTED: {
      return (
        // @ts-expect-error - Further investigation is needed as looks like IX2_RAW_DATA_IMPORTED is never triggered with ixParameters
        action.payload.ixParameters || {/*mutable flat state*/}
      );
    }
    case IX2_SESSION_STOPPED: {
      return {/*mutable flat state*/};
    }
    case IX2_PARAMETER_CHANGED: {
      const {key, value} = action.payload;
      state[key] = value;
      return state;
    }
    default: {
      return state;
    }
  }
};
