import {ActionTypeConsts} from './animation-actions';

const {
  TRANSFORM_MOVE,
  TRANSFORM_SCALE,
  TRANSFORM_ROTATE,
  TRANSFORM_SKEW,
  STYLE_SIZE,
  STYLE_FILTER,
  STYLE_FONT_VARIATION,
} = ActionTypeConsts;

/**
 * Reduced Motion: Action types to bypass during `prefers-reduced-motion`
 */

export const ReducedMotionTypes = {
  [TRANSFORM_MOVE]: true,
  [TRANSFORM_SCALE]: true,
  [TRANSFORM_ROTATE]: true,
  [TRANSFORM_SKEW]: true,
  [STYLE_SIZE]: true,
  [STYLE_FILTER]: true,
  [STYLE_FONT_VARIATION]: true,
} as const;
