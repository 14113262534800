/* eslint-env browser */

import {
  IX2EngineConstants,
  IX2EngineActionTypes,
} from '@packages/systems/ix2/shared-constants';

import {IX2EasingUtils, IX2VanillaUtils} from '@packages/systems/ix2/shared';

const {
  IX2_RAW_DATA_IMPORTED,
  IX2_SESSION_STOPPED,
  IX2_INSTANCE_ADDED,
  IX2_INSTANCE_STARTED,
  IX2_INSTANCE_REMOVED,
  IX2_ANIMATION_FRAME_CHANGED,
} = IX2EngineActionTypes;
const {optimizeFloat, applyEasing, createBezierEasing} = IX2EasingUtils;
const {RENDER_GENERAL} = IX2EngineConstants;
const {getItemConfigByKey, getRenderType, getStyleProp} = IX2VanillaUtils;

import {set, merge, mergeIn} from 'timm';

const continuousInstance = (state: any, action: any) => {
  const {
    position: lastPosition,
    parameterId,
    actionGroups,
    destinationKeys,
    smoothing,
    restingValue,
    actionTypeId,
    customEasingFn,
    skipMotion,
    skipToValue,
  } = state;

  const {parameters} = action.payload;
  let velocity = Math.max(1 - smoothing, 0.01);
  let paramValue = parameters[parameterId];
  if (paramValue == null) {
    velocity = 1;
    paramValue = restingValue;
  }
  const nextPosition = Math.max(paramValue, 0) || 0;
  const positionDiff = optimizeFloat(nextPosition - lastPosition);
  const position = skipMotion
    ? skipToValue
    : optimizeFloat(lastPosition + positionDiff * velocity);
  const keyframePosition = position * 100;

  if (position === lastPosition && state.current) {
    return state;
  }

  let fromActionItem;
  let toActionItem;
  let positionOffset;
  let positionRange;

  for (let i = 0, {length} = actionGroups; i < length; i++) {
    const {keyframe, actionItems} = actionGroups[i];

    if (i === 0) {
      fromActionItem = actionItems[0];
    }

    if (keyframePosition >= keyframe) {
      fromActionItem = actionItems[0];

      const nextGroup = actionGroups[i + 1];
      const hasNextItem = nextGroup && keyframePosition !== keyframe;

      toActionItem = hasNextItem ? nextGroup.actionItems[0] : null;

      if (hasNextItem) {
        positionOffset = keyframe / 100;
        positionRange = (nextGroup.keyframe - keyframe) / 100;
      }
    }
  }

  const current: Record<string, any> = {};

  if (fromActionItem && !toActionItem) {
    for (let i = 0, {length} = destinationKeys; i < length; i++) {
      const key = destinationKeys[i];
      current[key] = getItemConfigByKey(
        actionTypeId,
        key,
        fromActionItem.config
      );
    }
  } else if (
    fromActionItem &&
    toActionItem &&
    positionOffset !== undefined &&
    positionRange !== undefined
  ) {
    const localPosition = (position - positionOffset) / positionRange;
    const easing = fromActionItem.config.easing;
    const eased = applyEasing(easing, localPosition, customEasingFn);
    for (let i = 0, {length} = destinationKeys; i < length; i++) {
      const key = destinationKeys[i];
      const fromVal = getItemConfigByKey(
        actionTypeId,
        key,
        fromActionItem.config
      );
      const toVal = getItemConfigByKey(actionTypeId, key, toActionItem.config);
      const diff = toVal - fromVal;
      const value = diff * eased + fromVal;
      current[key] = value;
    }
  }

  return merge(state, {
    position,
    current,
  });
};

const timedInstance = (state: any, action: any) => {
  const {
    active,
    origin,
    start,
    immediate,
    renderType,
    verbose,
    actionItem,
    destination,
    destinationKeys,
    pluginDuration,
    instanceDelay,
    customEasingFn,
    skipMotion,
  } = state;

  const easing = actionItem.config.easing;
  let {duration, delay} = actionItem.config;

  if (pluginDuration != null) {
    duration = pluginDuration;
  }

  delay = instanceDelay != null ? instanceDelay : delay;

  if (renderType === RENDER_GENERAL) {
    duration = 0;
  } else if (immediate || skipMotion) {
    duration = delay = 0;
  }
  const {now} = action.payload;

  if (active && origin) {
    const delta = now - (start + delay);

    if (verbose) {
      const verboseDelta = now - start;
      const verboseDuration = duration + delay;
      const verbosePosition = optimizeFloat(
        Math.min(Math.max(0, verboseDelta / verboseDuration), 1)
      );
      state = set(
        state,
        'verboseTimeElapsed',
        verboseDuration * verbosePosition
      );
    }

    if (delta < 0) {
      return state;
    }

    const position = optimizeFloat(Math.min(Math.max(0, delta / duration), 1));
    const eased = applyEasing(easing, position, customEasingFn);

    const newProps: Record<string, any> = {};

    let current = null;

    if (destinationKeys.length) {
      // @ts-expect-error - TS2347 - Untyped function calls may not accept type arguments. | TS7006 - Parameter 'result' implicitly has an 'any' type. | TS7006 - Parameter 'key' implicitly has an 'any' type.
      current = destinationKeys.reduce<Record<string, any>>((result, key) => {
        const destValue = destination[key];
        const originVal = parseFloat(origin[key]) || 0;
        const diff = parseFloat(destValue) - originVal;
        const value = diff * eased + originVal;
        result[key] = value;
        return result;
      }, {});
    }

    newProps.current = current;
    newProps.position = position;

    if (position === 1) {
      newProps.active = false;
      newProps.complete = true;
    }

    return merge(state, newProps);
  }
  return state;
};

type ixInstancesReducerState = Record<
  string,
  // TODO: many of the valid fields are still missing in this shape - add them, please
  {continuous: boolean; actionListId: string; verbose: boolean}
>;

export const ixInstances = (
  state: ixInstancesReducerState = Object.freeze({}),
  action: any
): ixInstancesReducerState => {
  switch (action.type) {
    case IX2_RAW_DATA_IMPORTED: {
      return action.payload.ixInstances || Object.freeze({});
    }
    case IX2_SESSION_STOPPED: {
      return Object.freeze({});
    }
    case IX2_INSTANCE_ADDED: {
      const {
        instanceId,
        elementId,
        actionItem,
        eventId,
        eventTarget,
        eventStateKey,
        actionListId,
        groupIndex,
        isCarrier,
        origin,
        destination,
        immediate,
        verbose,
        continuous,
        parameterId,
        actionGroups,
        smoothing,
        restingValue,
        pluginInstance,
        pluginDuration,
        instanceDelay,
        skipMotion,
        skipToValue,
      } = action.payload;

      const {actionTypeId} = actionItem;
      const renderType = getRenderType(actionTypeId);
      const styleProp = getStyleProp(renderType, actionTypeId);
      const destinationKeys = Object.keys(destination).filter(
        (key) =>
          // Skip null destination values
          destination[key] != null &&
          // Skip string destination values
          typeof destination[key] !== 'string'
      );

      const {easing} = actionItem.config;

      return set(state, instanceId, {
        id: instanceId,
        elementId,
        active: false,
        position: 0,
        start: 0,
        origin,
        destination,
        destinationKeys,
        immediate,
        verbose,
        current: null,
        actionItem,
        actionTypeId,
        eventId,
        eventTarget,
        eventStateKey,
        actionListId,
        groupIndex,
        renderType,
        isCarrier,
        styleProp,
        continuous,
        parameterId,
        actionGroups,
        smoothing,
        restingValue,
        pluginInstance,
        pluginDuration,
        instanceDelay,
        skipMotion,
        skipToValue,
        customEasingFn:
          Array.isArray(easing) && easing.length === 4
            ? // @ts-expect-error - TS2345 - Argument of type 'any[]' is not assignable to parameter of type 'IX2EasingCustomType'.
              createBezierEasing(easing)
            : undefined,
      });
    }
    case IX2_INSTANCE_STARTED: {
      const {instanceId, time} = action.payload;
      return mergeIn(state, [instanceId], {
        active: true,
        complete: false,
        start: time,
      });
    }
    case IX2_INSTANCE_REMOVED: {
      const {instanceId} = action.payload;
      if (!state[instanceId]) {
        return state;
      }
      const newState: Record<string, any> = {};
      const keys = Object.keys(state);
      const {length} = keys;
      for (let i = 0; i < length; i++) {
        const key = keys[i];
        if (key !== instanceId) {
          // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type. | TS2538 - Type 'undefined' cannot be used as an index type.
          newState[key] = state[key];
        }
      }
      return newState;
    }
    case IX2_ANIMATION_FRAME_CHANGED: {
      let newState = state;
      const keys = Object.keys(state);
      const {length} = keys;
      for (let i = 0; i < length; i++) {
        const key = keys[i];
        // @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
        const instance = state[key];
        const reducer = instance.continuous
          ? continuousInstance
          : timedInstance;
        // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'Key'.
        newState = set(newState, key, reducer(instance, action));
      }
      return newState;
    }
    default: {
      return state;
    }
  }
};
