/* eslint-env browser */
import flow from 'lodash/flow';
import get from 'lodash/get';
import clamp from 'lodash/clamp';

import {
  EventTypeConsts,
  EventBasedOn,
  IX2EngineConstants,
} from '@packages/systems/ix2/shared-constants';

import {stopActionGroup, startActionGroup} from './IX2VanillaEngine';
import {parameterChanged} from '../actions/IX2EngineActions';
import {IX2VanillaUtils} from '@packages/systems/ix2/shared';

const {
  MOUSE_CLICK,
  MOUSE_SECOND_CLICK,
  MOUSE_DOWN,
  MOUSE_UP,
  MOUSE_OVER,
  MOUSE_OUT,
  DROPDOWN_CLOSE,
  DROPDOWN_OPEN,
  SLIDER_ACTIVE,
  SLIDER_INACTIVE,
  TAB_ACTIVE,
  TAB_INACTIVE,
  NAVBAR_CLOSE,
  NAVBAR_OPEN,
  MOUSE_MOVE,
  PAGE_SCROLL_DOWN,
  SCROLL_INTO_VIEW,
  SCROLL_OUT_OF_VIEW,
  PAGE_SCROLL_UP,
  SCROLLING_IN_VIEW,
  PAGE_FINISH,
  ECOMMERCE_CART_CLOSE,
  ECOMMERCE_CART_OPEN,
  PAGE_START,
  PAGE_SCROLL,
} = EventTypeConsts;

const COMPONENT_ACTIVE = 'COMPONENT_ACTIVE';
const COMPONENT_INACTIVE = 'COMPONENT_INACTIVE';

const {COLON_DELIMITER} = IX2EngineConstants;

const {getNamespacedParameterId} = IX2VanillaUtils;

const composableFilter =
  (predicate: ((arg1?: any) => any) | ((arg1?: any) => boolean)) =>
  (options: any) => {
    if (typeof options === 'object' && predicate(options)) {
      return true;
    }
    return options;
  };

const isElement = composableFilter(({element, nativeEvent}) => {
  return element === nativeEvent.target;
});

const containsElement = composableFilter(({element, nativeEvent}) => {
  return element.contains(nativeEvent.target);
});

const isOrContainsElement = flow([isElement, containsElement]);

const getAutoStopEvent = (store: any, autoStopEventId: any) => {
  if (autoStopEventId) {
    const {ixData} = store.getState();
    const {events} = ixData;
    const eventToStop = events[autoStopEventId];
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly PAGE_START: "PAGE_START"; readonly PAGE_FINISH: "PAGE_FINISH"; }'.
    if (eventToStop && !AUTO_STOP_DISABLED_EVENTS[eventToStop.eventTypeId]) {
      return eventToStop;
    }
  }
  return null;
};

// @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type. | TS7031 - Binding element 'event' implicitly has an 'any' type.
const hasAutoStopEvent = ({store, event}) => {
  const {action: eventAction} = event;
  const {autoStopEventId} = eventAction.config;
  return Boolean(getAutoStopEvent(store, autoStopEventId));
};

// @ts-expect-error - TS7031 - Binding element 'store' implicitly has an 'any' type. | TS7031 - Binding element 'event' implicitly has an 'any' type. | TS7031 - Binding element 'element' implicitly has an 'any' type. | TS7031 - Binding element 'eventStateKey' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
const actionGroupCreator = ({store, event, element, eventStateKey}, state) => {
  const {action: eventAction, id: eventId} = event;
  const {actionListId, autoStopEventId} = eventAction.config;
  const eventToStop = getAutoStopEvent(store, autoStopEventId);
  if (eventToStop) {
    stopActionGroup({
      store,
      eventId: autoStopEventId,
      eventTarget: element,
      eventStateKey:
        autoStopEventId +
        COLON_DELIMITER +
        eventStateKey.split(COLON_DELIMITER)[1],
      actionListId: get(eventToStop, 'action.config.actionListId'),
    });
  }
  stopActionGroup({
    store,
    eventId,
    eventTarget: element,
    eventStateKey,
    actionListId,
  });
  // @ts-expect-error - TS2345 - Argument of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; }' is not assignable to parameter of type '{ store: any; eventId: any; eventTarget: any; eventStateKey: any; actionListId: any; groupIndex?: number | undefined; immediate: any; verbose: any; }'.
  startActionGroup({
    store,
    eventId,
    eventTarget: element,
    eventStateKey,
    actionListId,
  });
  return state;
};

// @ts-expect-error - TS7006 - Parameter 'filter' implicitly has an 'any' type. | TS7006 - Parameter 'handler' implicitly has an 'any' type.
const withFilter = (filter, handler) => (options: any, state: any) =>
  filter(options, state) === true ? handler(options, state) : state;

const baseActionGroupOptions = {
  handler: withFilter(isOrContainsElement, actionGroupCreator),
} as const;

const baseActivityActionGroupOptions = {
  ...baseActionGroupOptions,
  types: [COMPONENT_ACTIVE, COMPONENT_INACTIVE].join(' '),
} as const;

const SCROLL_EVENT_TYPES = [
  {target: window, types: 'resize orientationchange', throttle: true},
  {
    target: document,
    types: 'scroll wheel readystatechange IX2_PAGE_UPDATE',
    throttle: true,
  },
];

const MOUSE_OVER_OUT_TYPES = 'mouseover mouseout';

const baseScrollActionGroupOptions = {
  types: SCROLL_EVENT_TYPES,
} as const;

const AUTO_STOP_DISABLED_EVENTS = {
  PAGE_START,
  PAGE_FINISH,
} as const;

const getDocumentState = (() => {
  const supportOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = document.compatMode === 'CSS1Compat';
  const rootElement = isCSS1Compat ? document.documentElement : document.body;
  return () => ({
    scrollLeft: supportOffset ? window.pageXOffset : rootElement.scrollLeft,

    scrollTop: supportOffset ? window.pageYOffset : rootElement.scrollTop,

    // required to remove elasticity in Safari scrolling.
    stiffScrollTop: clamp(
      supportOffset ? window.pageYOffset : rootElement.scrollTop,
      0,

      rootElement.scrollHeight - window.innerHeight
    ),

    scrollWidth: rootElement.scrollWidth,

    scrollHeight: rootElement.scrollHeight,

    clientWidth: rootElement.clientWidth,

    clientHeight: rootElement.clientHeight,
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  });
})();

const areBoxesIntersecting = (
  a: any,
  b: {
    bottom: number;
    left: number;
    right: number;
    top: any | number;
  }
) =>
  !(
    a.left > b.right ||
    a.right < b.left ||
    a.top > b.bottom ||
    a.bottom < b.top
  );

// @ts-expect-error - TS7031 - Binding element 'element' implicitly has an 'any' type. | TS7031 - Binding element 'nativeEvent' implicitly has an 'any' type.
const isElementHovered = ({element, nativeEvent}) => {
  const {type, target, relatedTarget} = nativeEvent;
  const containsTarget = element.contains(target);
  if (type === 'mouseover' && containsTarget) {
    return true;
  }
  const containsRelated = element.contains(relatedTarget);
  if (type === 'mouseout' && containsTarget && containsRelated) {
    return true;
  }
  return false;
};

const isElementVisible = (options: any) => {
  const {
    element,
    event: {config},
  } = options;

  const {clientWidth, clientHeight} = getDocumentState();
  const scrollOffsetValue = config.scrollOffsetValue;
  const scrollOffsetUnit = config.scrollOffsetUnit;
  const isPX = scrollOffsetUnit === 'PX';

  const offsetPadding = isPX
    ? scrollOffsetValue
    : (clientHeight * (scrollOffsetValue || 0)) / 100;

  return areBoxesIntersecting(element.getBoundingClientRect(), {
    left: 0,
    top: offsetPadding,
    right: clientWidth,
    bottom: clientHeight - offsetPadding,
  });
};

const whenComponentActiveChange =
  // @ts-expect-error - TS7006 - Parameter 'handler' implicitly has an 'any' type.
  (handler) => (options: any, oldState: any) => {
    const {type} = options.nativeEvent;
    // prettier-ignore
    const isActive = [COMPONENT_ACTIVE, COMPONENT_INACTIVE].indexOf(type) !== -1
    ? type === COMPONENT_ACTIVE
    : oldState.isActive;

    const newState = {
      ...oldState,
      isActive,
    } as const;

    if (!oldState || newState.isActive !== oldState.isActive) {
      return handler(options, newState) || newState;
    }

    return newState;
  };

const whenElementHoverChange =
  (
    handler: (
      options: any,
      state: {
        elementHovered: boolean;
      }
    ) => void
  ) =>
  (options: any, oldState: any) => {
    const newState = {
      elementHovered: isElementHovered(options),
    } as const;
    if (
      oldState
        ? newState.elementHovered !== oldState.elementHovered
        : newState.elementHovered
    ) {
      // @ts-expect-error - TS1345 - An expression of type 'void' cannot be tested for truthiness.
      return handler(options, newState) || newState;
    }
    return newState;
  };

const whenElementVisibiltyChange =
  (handler: (options?: any, state?: any) => any) =>
  (options: any, oldState: any) => {
    const newState = {
      ...oldState,
      elementVisible: isElementVisible(options),
    } as const;
    if (
      oldState
        ? newState.elementVisible !== oldState.elementVisible
        : newState.elementVisible
    ) {
      return handler(options, newState) || newState;
    }
    return newState;
  };

const whenScrollDirectionChange =
  // @ts-expect-error - TS7006 - Parameter 'handler' implicitly has an 'any' type.


    (handler) =>
    (options: any, oldState = {}) => {
      const {
        stiffScrollTop: scrollTop,
        scrollHeight,
        innerHeight,
      } = getDocumentState();
      const {
        event: {config, eventTypeId},
      } = options;
      const {scrollOffsetValue, scrollOffsetUnit} = config;
      const isPX = scrollOffsetUnit === 'PX';

      const scrollHeightBounds = scrollHeight - innerHeight;
      // percent top since innerHeight may change for mobile devices which also changes the scrollTop value.
      const percentTop = Number((scrollTop / scrollHeightBounds).toFixed(2));

      // no state change
      // @ts-expect-error - TS2339 - Property 'percentTop' does not exist on type '{}'.
      if (oldState && oldState.percentTop === percentTop) {
        return oldState;
      }

      const scrollTopPadding =
        (isPX
          ? scrollOffsetValue
          : (innerHeight * (scrollOffsetValue || 0)) / 100) /
        scrollHeightBounds;

      let scrollingDown;
      let scrollDirectionChanged;
      let anchorTop = 0;

      if (oldState) {
        // @ts-expect-error - TS2339 - Property 'percentTop' does not exist on type '{}'.
        scrollingDown = percentTop > oldState.percentTop;
        // @ts-expect-error - TS2339 - Property 'scrollingDown' does not exist on type '{}'.
        scrollDirectionChanged = oldState.scrollingDown !== scrollingDown;
        // @ts-expect-error - TS2339 - Property 'anchorTop' does not exist on type '{}'.
        anchorTop = scrollDirectionChanged ? percentTop : oldState.anchorTop;
      }

      const inBounds =
        eventTypeId === PAGE_SCROLL_DOWN
          ? percentTop >= anchorTop + scrollTopPadding
          : percentTop <= anchorTop - scrollTopPadding;

      const newState = {
        ...oldState,
        percentTop,
        inBounds,
        anchorTop,
        scrollingDown,
      } as const;

      if (
        oldState &&
        inBounds &&
        // @ts-expect-error - TS2339 - Property 'inBounds' does not exist on type '{}'.
        (scrollDirectionChanged || newState.inBounds !== oldState.inBounds)
      ) {
        return handler(options, newState) || newState;
      }

      return newState;
    };

const pointIntersects = (
  point: {
    left: any | number;
    top: any | number;
  },
  rect: any
) =>
  point.left > rect.left &&
  point.left < rect.right &&
  point.top > rect.top &&
  point.top < rect.bottom;

const whenPageLoadFinish =
  (handler: (arg1: any, state: undefined | any) => undefined | any) =>
  (options: any, oldState: any) => {
    const newState = {
      finished: document.readyState === 'complete',
    } as const;
    if (newState.finished && !(oldState && oldState.finshed)) {
      // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
      handler(options);
    }
    return newState;
  };

const whenPageLoadStart =
  (handler: (arg1: any, state: undefined | any) => undefined | any) =>
  (options: any, oldState: any) => {
    const newState = {
      started: true,
    } as const;
    if (!oldState) {
      // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
      handler(options);
    }
    return newState;
  };

const whenClickCountChange =
  (
    handler: (
      options: any,
      arg2: {
        clickCount: number;
      }
    ) => void
  ) =>
  (options: any, oldState = {clickCount: 0}) => {
    const newState = {
      clickCount: (oldState.clickCount % 2) + 1,
    } as const;
    if (newState.clickCount !== oldState.clickCount) {
      // @ts-expect-error - TS1345 - An expression of type 'void' cannot be tested for truthiness.
      return handler(options, newState) || newState;
    }
    return newState;
  };

const getComponentActiveOptions = (allowNestedChildrenEvents = true) => ({
  ...baseActivityActionGroupOptions,
  handler: withFilter(
    allowNestedChildrenEvents ? isOrContainsElement : isElement,
    // @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
    whenComponentActiveChange((options, state) => {
      return state.isActive
        ? baseActionGroupOptions.handler(options, state)
        : state;
    })
  ),
});

const getComponentInactiveOptions = (allowNestedChildrenEvents = true) => ({
  ...baseActivityActionGroupOptions,
  handler: withFilter(
    allowNestedChildrenEvents ? isOrContainsElement : isElement,
    // @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
    whenComponentActiveChange((options, state) => {
      return !state.isActive
        ? baseActionGroupOptions.handler(options, state)
        : state;
    })
  ),
});

const scrollIntoOutOfViewOptions = {
  ...baseScrollActionGroupOptions,
  handler: whenElementVisibiltyChange((options, state) => {
    const {elementVisible} = state;
    const {event, store} = options;
    const {ixData} = store.getState();
    const {events} = ixData;

    // trigger the handler only once if only one of SCROLL_INTO or SCROLL_OUT_OF event types
    // are registered.
    if (!events[event.action.config.autoStopEventId] && state.triggered) {
      return state;
    }

    if ((event.eventTypeId === SCROLL_INTO_VIEW) === elementVisible) {
      // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
      actionGroupCreator(options);
      return {
        ...state,
        triggered: true,
      };
    } else {
      return state;
    }
  }),
} as const;

const MOUSE_OUT_ROUND_THRESHOLD = 0.05;

export default {
  [SLIDER_ACTIVE]: getComponentActiveOptions(),
  [SLIDER_INACTIVE]: getComponentInactiveOptions(),
  [DROPDOWN_OPEN]: getComponentActiveOptions(),
  [DROPDOWN_CLOSE]: getComponentInactiveOptions(),

  // navbar elements may contain nested components in the menu. To prevent activity misfires, only listed for activity
  // events where the target is the navbar element, and ignore children that dispatch activitiy events.
  [NAVBAR_OPEN]: getComponentActiveOptions(false),
  [NAVBAR_CLOSE]: getComponentInactiveOptions(false),
  [TAB_ACTIVE]: getComponentActiveOptions(),
  [TAB_INACTIVE]: getComponentInactiveOptions(),
  [ECOMMERCE_CART_OPEN]: {
    types: 'ecommerce-cart-open',
    handler: withFilter(isOrContainsElement, actionGroupCreator),
  },
  [ECOMMERCE_CART_CLOSE]: {
    types: 'ecommerce-cart-close',
    handler: withFilter(isOrContainsElement, actionGroupCreator),
  },
  [MOUSE_CLICK]: {
    types: 'click',
    handler: withFilter(
      isOrContainsElement,
      whenClickCountChange((options, {clickCount}) => {
        if (hasAutoStopEvent(options)) {
          // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
          clickCount === 1 && actionGroupCreator(options);
        } else {
          // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
          actionGroupCreator(options);
        }
      })
    ),
  },
  [MOUSE_SECOND_CLICK]: {
    types: 'click',
    handler: withFilter(
      isOrContainsElement,
      whenClickCountChange((options, {clickCount}) => {
        if (clickCount === 2) {
          // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
          actionGroupCreator(options);
        }
      })
    ),
  },
  [MOUSE_DOWN]: {
    ...baseActionGroupOptions,
    types: 'mousedown',
  },
  [MOUSE_UP]: {
    ...baseActionGroupOptions,
    types: 'mouseup',
  },
  [MOUSE_OVER]: {
    types: MOUSE_OVER_OUT_TYPES,
    handler: withFilter(
      isOrContainsElement,
      whenElementHoverChange((options, state) => {
        if (state.elementHovered) {
          // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
          actionGroupCreator(options);
        }
      })
    ),
  },
  [MOUSE_OUT]: {
    types: MOUSE_OVER_OUT_TYPES,
    handler: withFilter(
      isOrContainsElement,
      whenElementHoverChange((options, state) => {
        if (!state.elementHovered) {
          // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
          actionGroupCreator(options);
        }
      })
    ),
  },
  [MOUSE_MOVE]: {
    types: 'mousemove mouseout scroll',
    handler: (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      {store, element, eventConfig, nativeEvent, eventStateKey},

      state = {clientX: 0, clientY: 0, pageX: 0, pageY: 0}
    ) => {
      const {
        basedOn,
        selectedAxis,
        continuousParameterGroupId,
        reverse,
        restingState = 0,
      } = eventConfig;
      const {
        clientX = state.clientX,
        clientY = state.clientY,
        pageX = state.pageX,
        pageY = state.pageY,
      } = nativeEvent;
      const isXAxis = selectedAxis === 'X_AXIS';
      const isMouseOut = nativeEvent.type === 'mouseout';

      let value = restingState / 100;
      let namespacedParameterId = continuousParameterGroupId;
      let elementHovered = false;

      switch (basedOn) {
        case EventBasedOn.VIEWPORT: {
          value = isXAxis
            ? Math.min(clientX, window.innerWidth) / window.innerWidth
            : Math.min(clientY, window.innerHeight) / window.innerHeight;
          break;
        }
        // @ts-expect-error - TS2339 - Property 'PAGE' does not exist on type '{ readonly ELEMENT: "ELEMENT"; readonly VIEWPORT: "VIEWPORT"; }'.
        case EventBasedOn.PAGE: {
          const {scrollLeft, scrollTop, scrollWidth, scrollHeight} =
            getDocumentState();
          value = isXAxis
            ? Math.min(scrollLeft + pageX, scrollWidth) / scrollWidth
            : Math.min(scrollTop + pageY, scrollHeight) / scrollHeight;
          break;
        }
        case EventBasedOn.ELEMENT:
        default: {
          namespacedParameterId = getNamespacedParameterId(
            eventStateKey,
            continuousParameterGroupId
          );

          const isMouseEvent = nativeEvent.type.indexOf('mouse') === 0;

          // Use isOrContainsElement for mouse events since they are fired from the target
          if (
            isMouseEvent &&
            isOrContainsElement({element, nativeEvent}) !== true
          ) {
            break;
          }

          const rect = element.getBoundingClientRect();
          const {left, top, width, height} = rect;

          // Otherwise we'll need to calculate the mouse position from the previous handler state
          // against the target element's rect
          if (
            !isMouseEvent &&
            !pointIntersects({left: clientX, top: clientY}, rect)
          ) {
            break;
          }

          elementHovered = true;

          value = isXAxis ? (clientX - left) / width : (clientY - top) / height;
          break;
        }
      }

      // cover case where the event is a mouse out, but the value is not quite at 100%
      if (
        isMouseOut &&
        (value > 1 - MOUSE_OUT_ROUND_THRESHOLD ||
          value < MOUSE_OUT_ROUND_THRESHOLD)
      ) {
        value = Math.round(value);
      }

      // Only update based on element if the mouse is moving over or has just left the element
      if (
        basedOn !== EventBasedOn.ELEMENT ||
        elementHovered ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        elementHovered !== state.elementHovered
      ) {
        value = reverse ? 1 - value : value;
        store.dispatch(parameterChanged(namespacedParameterId, value));
      }

      return {
        elementHovered,
        clientX,
        clientY,
        pageX,
        pageY,
      };
    },
  },

  [PAGE_SCROLL]: {
    types: SCROLL_EVENT_TYPES,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    handler: ({store, eventConfig}) => {
      const {continuousParameterGroupId, reverse} = eventConfig;
      const {scrollTop, scrollHeight, clientHeight} = getDocumentState();
      let value = scrollTop / (scrollHeight - clientHeight);
      value = reverse ? 1 - value : value;
      store.dispatch(parameterChanged(continuousParameterGroupId, value));
    },
  },

  [SCROLLING_IN_VIEW]: {
    types: SCROLL_EVENT_TYPES,
    handler: (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      {element, store, eventConfig, eventStateKey},

      state = {scrollPercent: 0}
    ) => {
      const {
        scrollLeft,
        scrollTop,
        scrollWidth,
        scrollHeight,
        clientHeight: visibleHeight,
      } = getDocumentState();

      const {
        basedOn,
        selectedAxis,
        continuousParameterGroupId,
        startsEntering,
        startsExiting,
        addEndOffset,
        addStartOffset,
        addOffsetValue = 0,
        endOffsetValue = 0,
      } = eventConfig;

      const isXAxis = selectedAxis === 'X_AXIS';

      if (basedOn === EventBasedOn.VIEWPORT) {
        const value = isXAxis
          ? scrollLeft / scrollWidth
          : scrollTop / scrollHeight;
        if (value !== state.scrollPercent) {
          store.dispatch(parameterChanged(continuousParameterGroupId, value));
        }
        return {
          scrollPercent: value,
        };
      } else {
        const namespacedParameterId = getNamespacedParameterId(
          eventStateKey,
          continuousParameterGroupId
        );
        const elementRect = element.getBoundingClientRect();
        let offsetStartPerc = (addStartOffset ? addOffsetValue : 0) / 100;
        let offsetEndPerc = (addEndOffset ? endOffsetValue : 0) / 100;

        // flip the offset percentages depending on start / exit type
        offsetStartPerc = startsEntering
          ? offsetStartPerc
          : 1 - offsetStartPerc;
        offsetEndPerc = startsExiting ? offsetEndPerc : 1 - offsetEndPerc;

        const offsetElementTop =
          elementRect.top +
          Math.min(elementRect.height * offsetStartPerc, visibleHeight);
        const offsetElementBottom =
          elementRect.top + elementRect.height * offsetEndPerc;
        const offsetHeight = offsetElementBottom - offsetElementTop;

        const fixedScrollHeight = Math.min(
          visibleHeight + offsetHeight,
          scrollHeight
        );

        const fixedScrollTop = Math.min(
          Math.max(0, visibleHeight - offsetElementTop),
          fixedScrollHeight
        );
        const fixedScrollPerc = fixedScrollTop / fixedScrollHeight;

        if (fixedScrollPerc !== state.scrollPercent) {
          store.dispatch(
            parameterChanged(namespacedParameterId, fixedScrollPerc)
          );
        }
        return {
          scrollPercent: fixedScrollPerc,
        };
      }
    },
  },
  [SCROLL_INTO_VIEW]: scrollIntoOutOfViewOptions,
  [SCROLL_OUT_OF_VIEW]: scrollIntoOutOfViewOptions,

  [PAGE_SCROLL_DOWN]: {
    ...baseScrollActionGroupOptions,
    // @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
    handler: whenScrollDirectionChange((options, state) => {
      if (state.scrollingDown) {
        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        actionGroupCreator(options);
      }
    }),
  },

  [PAGE_SCROLL_UP]: {
    ...baseScrollActionGroupOptions,
    // @ts-expect-error - TS7006 - Parameter 'options' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
    handler: whenScrollDirectionChange((options, state) => {
      if (!state.scrollingDown) {
        // @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
        actionGroupCreator(options);
      }
    }),
  },

  [PAGE_FINISH]: {
    types: 'readystatechange IX2_PAGE_UPDATE',
    handler: withFilter(isElement, whenPageLoadFinish(actionGroupCreator)),
  },

  [PAGE_START]: {
    types: 'readystatechange IX2_PAGE_UPDATE',
    handler: withFilter(isElement, whenPageLoadStart(actionGroupCreator)),
  },
};
