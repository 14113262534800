// @ts-expect-error - TS7016 - Could not find a declaration file for module 'bezier-easing'. '/home/runner/work/flow-to-typescript-codemod/flow-to-typescript-codemod/webflow/node_modules/bezier-easing/src/index.js' implicitly has an 'any' type.
import BezierEasing from 'bezier-easing';

// Easing functions adapted from Thomas Fuchs & Jeremy Kahn
// Easing Equations (c) 2003 Robert Penner, BSD license
// https://raw.github.com/danro/easing-js/master/LICENSE

const magicSwing = 1.70158;

type EasingFunction = (arg1: number) => number;

export const ease: EasingFunction = BezierEasing(0.25, 0.1, 0.25, 1.0);
export const easeIn: EasingFunction = BezierEasing(0.42, 0.0, 1.0, 1.0);
export const easeOut: EasingFunction = BezierEasing(0.0, 0.0, 0.58, 1.0);
export const easeInOut: EasingFunction = BezierEasing(0.42, 0.0, 0.58, 1.0);

export function inQuad(pos: number): number {
  return Math.pow(pos, 2);
}

export function outQuad(pos: number): number {
  return -(Math.pow(pos - 1, 2) - 1);
}

export function inOutQuad(pos: number): number {
  if ((pos /= 0.5) < 1) {
    return 0.5 * Math.pow(pos, 2);
  }
  return -0.5 * ((pos -= 2) * pos - 2);
}

export function inCubic(pos: number): number {
  return Math.pow(pos, 3);
}

export function outCubic(pos: number): number {
  return Math.pow(pos - 1, 3) + 1;
}

export function inOutCubic(pos: number): number {
  if ((pos /= 0.5) < 1) {
    return 0.5 * Math.pow(pos, 3);
  }
  return 0.5 * (Math.pow(pos - 2, 3) + 2);
}

export function inQuart(pos: number): number {
  return Math.pow(pos, 4);
}

export function outQuart(pos: number): number {
  return -(Math.pow(pos - 1, 4) - 1);
}

export function inOutQuart(pos: number): number {
  if ((pos /= 0.5) < 1) {
    return 0.5 * Math.pow(pos, 4);
  }
  return -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
}

export function inQuint(pos: number): number {
  return Math.pow(pos, 5);
}

export function outQuint(pos: number): number {
  return Math.pow(pos - 1, 5) + 1;
}

export function inOutQuint(pos: number): number {
  if ((pos /= 0.5) < 1) {
    return 0.5 * Math.pow(pos, 5);
  }
  return 0.5 * (Math.pow(pos - 2, 5) + 2);
}

export function inSine(pos: number): number {
  return -Math.cos(pos * (Math.PI / 2)) + 1;
}

export function outSine(pos: number): number {
  return Math.sin(pos * (Math.PI / 2));
}

export function inOutSine(pos: number): number {
  return -0.5 * (Math.cos(Math.PI * pos) - 1);
}

export function inExpo(pos: number): number {
  return pos === 0 ? 0 : Math.pow(2, 10 * (pos - 1));
}

export function outExpo(pos: number): number {
  return pos === 1 ? 1 : -Math.pow(2, -10 * pos) + 1;
}

export function inOutExpo(pos: number): number {
  if (pos === 0) {
    return 0;
  }
  if (pos === 1) {
    return 1;
  }
  if ((pos /= 0.5) < 1) {
    return 0.5 * Math.pow(2, 10 * (pos - 1));
  }
  return 0.5 * (-Math.pow(2, -10 * --pos) + 2);
}

export function inCirc(pos: number): number {
  return -(Math.sqrt(1 - pos * pos) - 1);
}

export function outCirc(pos: number): number {
  return Math.sqrt(1 - Math.pow(pos - 1, 2));
}

export function inOutCirc(pos: number): number {
  if ((pos /= 0.5) < 1) {
    return -0.5 * (Math.sqrt(1 - pos * pos) - 1);
  }
  return 0.5 * (Math.sqrt(1 - (pos -= 2) * pos) + 1);
}

export function outBounce(pos: number): number {
  if (pos < 1 / 2.75) {
    return 7.5625 * pos * pos;
  } else if (pos < 2 / 2.75) {
    return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
  } else if (pos < 2.5 / 2.75) {
    return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
  } else {
    return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
  }
}

export function inBack(pos: number): number {
  const s = magicSwing;
  return pos * pos * ((s + 1) * pos - s);
}

export function outBack(pos: number): number {
  const s = magicSwing;
  return (pos -= 1) * pos * ((s + 1) * pos + s) + 1;
}

export function inOutBack(pos: number): number {
  let s = magicSwing;
  if ((pos /= 0.5) < 1) {
    return 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s));
  }
  return 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
}

export function inElastic(pos: number): number {
  let s = magicSwing;
  let p = 0;
  let a = 1;
  if (pos === 0) {
    return 0;
  }
  if (pos === 1) {
    return 1;
  }
  if (!p) {
    p = 0.3;
  }
  if (a < 1) {
    a = 1;
    s = p / 4;
  } else {
    s = (p / (2 * Math.PI)) * Math.asin(1 / a);
  }
  return -(
    a *
    Math.pow(2, 10 * (pos -= 1)) *
    Math.sin(((pos - s) * (2 * Math.PI)) / p)
  );
}

export function outElastic(pos: number): number {
  let s = magicSwing;
  let p = 0;
  let a = 1;
  if (pos === 0) {
    return 0;
  }
  if (pos === 1) {
    return 1;
  }
  if (!p) {
    p = 0.3;
  }
  if (a < 1) {
    a = 1;
    s = p / 4;
  } else {
    s = (p / (2 * Math.PI)) * Math.asin(1 / a);
  }
  return (
    a * Math.pow(2, -10 * pos) * Math.sin(((pos - s) * (2 * Math.PI)) / p) + 1
  );
}

export function inOutElastic(pos: number): number {
  let s = magicSwing;
  let p = 0;
  let a = 1;
  if (pos === 0) {
    return 0;
  }
  if ((pos /= 1 / 2) === 2) {
    return 1;
  }
  if (!p) {
    p = 0.3 * 1.5;
  }
  if (a < 1) {
    a = 1;
    s = p / 4;
  } else {
    s = (p / (2 * Math.PI)) * Math.asin(1 / a);
  }
  if (pos < 1) {
    return (
      -0.5 *
      (a *
        Math.pow(2, 10 * (pos -= 1)) *
        Math.sin(((pos - s) * (2 * Math.PI)) / p))
    );
  }
  return (
    a *
      Math.pow(2, -10 * (pos -= 1)) *
      Math.sin(((pos - s) * (2 * Math.PI)) / p) *
      0.5 +
    1
  );
}

export function swingFromTo(pos: number): number {
  let s = magicSwing;
  return (pos /= 0.5) < 1
    ? 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s))
    : 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
}

export function swingFrom(pos: number): number {
  const s = magicSwing;
  return pos * pos * ((s + 1) * pos - s);
}

export function swingTo(pos: number): number {
  const s = magicSwing;
  return (pos -= 1) * pos * ((s + 1) * pos + s) + 1;
}

export function bounce(pos: number): number {
  if (pos < 1 / 2.75) {
    return 7.5625 * pos * pos;
  } else if (pos < 2 / 2.75) {
    return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
  } else if (pos < 2.5 / 2.75) {
    return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
  } else {
    return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
  }
}

export function bouncePast(pos: number): number {
  if (pos < 1 / 2.75) {
    return 7.5625 * pos * pos;
  } else if (pos < 2 / 2.75) {
    return 2 - (7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75);
  } else if (pos < 2.5 / 2.75) {
    return 2 - (7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375);
  } else {
    return 2 - (7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375);
  }
}
