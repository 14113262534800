export const IX2_ID_DELIMITER = '|';
/**
 * Stores pageId of the current page, so it can be accessed in the IX2BrowserApi
 */
export const WF_PAGE = 'data-wf-page';
export const W_MOD_JS = 'w-mod-js';
export const W_MOD_IX = 'w-mod-ix';
export const BOUNDARY_SELECTOR = '.w-dyn-item';
export const CONFIG_X_VALUE = 'xValue';
export const CONFIG_Y_VALUE = 'yValue';
export const CONFIG_Z_VALUE = 'zValue';
export const CONFIG_VALUE = 'value';
export const CONFIG_X_UNIT = 'xUnit';
export const CONFIG_Y_UNIT = 'yUnit';
export const CONFIG_Z_UNIT = 'zUnit';
export const CONFIG_UNIT = 'unit';
export const TRANSFORM = 'transform';
export const TRANSLATE_X = 'translateX';
export const TRANSLATE_Y = 'translateY';
export const TRANSLATE_Z = 'translateZ';
export const TRANSLATE_3D = 'translate3d';
export const SCALE_X = 'scaleX';
export const SCALE_Y = 'scaleY';
export const SCALE_Z = 'scaleZ';
export const SCALE_3D = 'scale3d';
export const ROTATE_X = 'rotateX';
export const ROTATE_Y = 'rotateY';
export const ROTATE_Z = 'rotateZ';
export const SKEW = 'skew';
export const SKEW_X = 'skewX';
export const SKEW_Y = 'skewY';
export const OPACITY = 'opacity';
export const FILTER = 'filter';
export const FONT_VARIATION_SETTINGS = 'font-variation-settings';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const BACKGROUND_COLOR = 'backgroundColor';
export const BACKGROUND = 'background';
export const BORDER_COLOR = 'borderColor';
export const COLOR = 'color';
export const DISPLAY = 'display';
export const FLEX = 'flex';
export const WILL_CHANGE = 'willChange';
export const AUTO = 'AUTO';
export const COMMA_DELIMITER = ',';
export const COLON_DELIMITER = ':';
export const BAR_DELIMITER = '|';
export const CHILDREN = 'CHILDREN';
export const IMMEDIATE_CHILDREN = 'IMMEDIATE_CHILDREN';
export const SIBLINGS = 'SIBLINGS';
export const PARENT = 'PARENT';
export const PRESERVE_3D = 'preserve-3d';
export const HTML_ELEMENT = 'HTML_ELEMENT';
export const PLAIN_OBJECT = 'PLAIN_OBJECT';
export const ABSTRACT_NODE = 'ABSTRACT_NODE';
export const RENDER_TRANSFORM = 'RENDER_TRANSFORM';
export const RENDER_GENERAL = 'RENDER_GENERAL';
export const RENDER_STYLE = 'RENDER_STYLE';
export const RENDER_PLUGIN = 'RENDER_PLUGIN';
