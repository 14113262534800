import {IX2EngineActionTypes} from '@packages/systems/ix2/shared-constants';
const {
  IX2_PREVIEW_REQUESTED,
  IX2_PLAYBACK_REQUESTED,
  IX2_STOP_REQUESTED,
  IX2_CLEAR_REQUESTED,
} = IX2EngineActionTypes;
import {setIn} from 'timm';
import {
  playbackRequestedPayload,
  previewRequestedPayload,
  stopRequestedPayload,
} from '../actions/IX2EngineActions';

const initialState = {
  preview: {} as previewRequestedPayload,
  playback: {} as playbackRequestedPayload,
  stop: {} as stopRequestedPayload,
  clear: {},
};

const stateKeys = Object.create(null, {
  [IX2_PREVIEW_REQUESTED]: {value: 'preview'},
  [IX2_PLAYBACK_REQUESTED]: {value: 'playback'},
  [IX2_STOP_REQUESTED]: {value: 'stop'},
  [IX2_CLEAR_REQUESTED]: {value: 'clear'},
});

type ixRequestState = {
  preview: previewRequestedPayload;
  playback: playbackRequestedPayload;
  stop: stopRequestedPayload;
  clear: Record<never, never>;
};

type ixRequestAction =
  | {
      type: typeof IX2_PREVIEW_REQUESTED;
      payload: previewRequestedPayload;
    }
  | {
      type: typeof IX2_PLAYBACK_REQUESTED;
      payload: playbackRequestedPayload;
    }
  | {
      type: typeof IX2_STOP_REQUESTED;
      payload: stopRequestedPayload;
    }
  | {
      type: typeof IX2_CLEAR_REQUESTED;
      payload?: never;
    };

export const ixRequest = (
  state: ixRequestState = initialState,
  action: ixRequestAction
): ixRequestState => {
  if (action.type in stateKeys) {
    const key = [stateKeys[action.type]] as any;
    return setIn(state, [key], {...action.payload});
  }
  return state;
};
