import {IX2EngineActionTypes} from '@packages/systems/ix2/shared-constants';
import {rawDataImportedPayload} from '../actions/IX2EngineActions';
const {IX2_RAW_DATA_IMPORTED} = IX2EngineActionTypes;

type ixDataReducerAction = {
  type: typeof IX2_RAW_DATA_IMPORTED;
  payload: rawDataImportedPayload;
};

export type ixDataReducerState = rawDataImportedPayload['ixData'];

export const ixData = (
  state: ixDataReducerState = Object.freeze(
    {}
  ) as rawDataImportedPayload['ixData'],
  action: ixDataReducerAction
) => {
  switch (action.type) {
    case IX2_RAW_DATA_IMPORTED: {
      return action.payload.ixData || Object.freeze({});
    }
    default: {
      return state;
    }
  }
};
