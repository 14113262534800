import {ActionTypeConsts} from '@packages/systems/ix2/shared-constants';

import * as lottie from './IX2Lottie';
import * as spline from './IX2Spline';
import * as rive from './IX2Rive';
import * as variable from './IX2Variable';

export type PluginType =
  | typeof ActionTypeConsts.PLUGIN_LOTTIE
  | typeof ActionTypeConsts.PLUGIN_SPLINE
  | typeof ActionTypeConsts.PLUGIN_VARIABLE
  | typeof ActionTypeConsts.PLUGIN_RIVE;

export const pluginMethodMap = new Map([
  [ActionTypeConsts.PLUGIN_LOTTIE, {...lottie}],
  [ActionTypeConsts.PLUGIN_SPLINE, {...spline}],
  [ActionTypeConsts.PLUGIN_RIVE, {...rive}],
  [ActionTypeConsts.PLUGIN_VARIABLE, {...variable}],
]);
