import * as IX2BrowserSupport from './logic/IX2BrowserSupport';
import * as IX2Easings from './logic/IX2Easings';
import * as IX2EasingUtils from './logic/IX2EasingUtils';
import * as IX2ElementsReducer from './reducers/IX2ElementsReducer';
import * as IX2VanillaPlugins from './logic/IX2VanillaPlugins';
import * as IX2VanillaUtils from './logic/IX2VanillaUtils';
export {
  // IX2Actions,
  IX2BrowserSupport,
  IX2Easings,
  IX2EasingUtils,
  IX2ElementsReducer,
  IX2VanillaPlugins,
  IX2VanillaUtils,
};
