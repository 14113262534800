export const IX2_RAW_DATA_IMPORTED = 'IX2_RAW_DATA_IMPORTED';
export const IX2_SESSION_INITIALIZED = 'IX2_SESSION_INITIALIZED';
export const IX2_SESSION_STARTED = 'IX2_SESSION_STARTED';
export const IX2_SESSION_STOPPED = 'IX2_SESSION_STOPPED';
export const IX2_PREVIEW_REQUESTED = 'IX2_PREVIEW_REQUESTED';
export const IX2_PLAYBACK_REQUESTED = 'IX2_PLAYBACK_REQUESTED';
export const IX2_STOP_REQUESTED = 'IX2_STOP_REQUESTED';
export const IX2_CLEAR_REQUESTED = 'IX2_CLEAR_REQUESTED';
export const IX2_EVENT_LISTENER_ADDED = 'IX2_EVENT_LISTENER_ADDED';
export const IX2_EVENT_STATE_CHANGED = 'IX2_EVENT_STATE_CHANGED';
export const IX2_ANIMATION_FRAME_CHANGED = 'IX2_ANIMATION_FRAME_CHANGED';
export const IX2_PARAMETER_CHANGED = 'IX2_PARAMETER_CHANGED';
export const IX2_INSTANCE_ADDED = 'IX2_INSTANCE_ADDED';
export const IX2_INSTANCE_STARTED = 'IX2_INSTANCE_STARTED';
export const IX2_INSTANCE_REMOVED = 'IX2_INSTANCE_REMOVED';
export const IX2_ELEMENT_STATE_CHANGED = 'IX2_ELEMENT_STATE_CHANGED';
export const IX2_ACTION_LIST_PLAYBACK_CHANGED =
  'IX2_ACTION_LIST_PLAYBACK_CHANGED';
export const IX2_VIEWPORT_WIDTH_CHANGED = 'IX2_VIEWPORT_WIDTH_CHANGED';
export const IX2_MEDIA_QUERIES_DEFINED = 'IX2_MEDIA_QUERIES_DEFINED';
export const IX2_TEST_FRAME_RENDERED = 'IX2_TEST_FRAME_RENDERED';
