import {IS_BROWSER_ENV} from './IX2BrowserSupport';
import {pluginMethodMap} from '@packages/systems/ix2/plugins';

import type {ActionTypeIdType} from '@packages/systems/ix2/types-core';
import type {PluginType} from '@packages/systems/ix2/plugins';

export function isPluginType(actionTypeId: ActionTypeIdType): boolean {
  // @ts-expect-error - TS2345 - Argument of type '"TRANSFORM_MOVE" | "TRANSFORM_SCALE" | "TRANSFORM_ROTATE" | "TRANSFORM_SKEW" | "STYLE_OPACITY" | "STYLE_SIZE" | "STYLE_FILTER" | "STYLE_FONT_VARIATION" | "STYLE_BACKGROUND_COLOR" | ... 12 more ... | "STYLE_BOX_SHADOW"' is not assignable to parameter of type '"PLUGIN_LOTTIE" | "PLUGIN_SPLINE" | "PLUGIN_VARIABLE"'.
  return pluginMethodMap.has(actionTypeId);
}

type PluginMethodName =
  | 'getPluginConfig'
  | 'getPluginOrigin'
  | 'getPluginDuration'
  | 'getPluginDestination'
  | 'createPluginInstance'
  | 'renderPlugin'
  | 'clearPlugin';

const pluginMethod =
  (methodName: PluginMethodName) => (actionTypeId: PluginType) => {
    if (!IS_BROWSER_ENV) {
      // IX2 plugins require browser libs for now
      return () => null;
    }
    const plugin = pluginMethodMap.get(actionTypeId);
    if (!plugin) {
      throw new Error(`IX2 no plugin configured for: ${actionTypeId}`);
    }
    const method = plugin[methodName];
    if (!method) {
      throw new Error(`IX2 invalid plugin method: ${methodName}`);
    }
    return method;
  };

export const getPluginConfig = pluginMethod('getPluginConfig');
export const getPluginOrigin = pluginMethod('getPluginOrigin');
export const getPluginDuration = pluginMethod('getPluginDuration');
export const getPluginDestination = pluginMethod('getPluginDestination');
export const createPluginInstance = pluginMethod('createPluginInstance');
export const renderPlugin = pluginMethod('renderPlugin');
export const clearPlugin = pluginMethod('clearPlugin');
