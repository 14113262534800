import type {
  IX2EasingEnumType,
  IX2EasingCustomType,
} from '@packages/systems/ix2/types-core';
import * as easings from './IX2Easings';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'bezier-easing'. '/home/runner/work/flow-to-typescript-codemod/flow-to-typescript-codemod/webflow/node_modules/bezier-easing/src/index.js' implicitly has an 'any' type.
import BezierEasing from 'bezier-easing';

export function optimizeFloat(
  value: number,
  digits: number = 5,
  base: number = 10
): number {
  const pow = Math.pow(base, digits);
  const float = Number(Math.round(value * pow) / pow);
  return Math.abs(float) > 0.0001 ? float : 0;
}

export function createBezierEasing(
  easing: IX2EasingCustomType
): (arg1: number) => number {
  return BezierEasing(...easing);
}

export function applyEasing(
  easing: IX2EasingEnumType,
  position: number,
  customEasingFn?: (arg1: number) => number
) {
  if (position === 0) {
    return 0;
  }
  if (position === 1) {
    return 1;
  }

  if (customEasingFn) {
    return optimizeFloat(position > 0 ? customEasingFn(position) : position);
  }

  return optimizeFloat(
    position > 0 && easing && easings[easing]
      ? easings[easing](position)
      : position
  );
}
