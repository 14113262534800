/**
 * Event Type IDs
 */

export const EventTypeConsts = {
  NAVBAR_OPEN: 'NAVBAR_OPEN' as const,
  NAVBAR_CLOSE: 'NAVBAR_CLOSE' as const,
  TAB_ACTIVE: 'TAB_ACTIVE' as const,
  TAB_INACTIVE: 'TAB_INACTIVE' as const,
  SLIDER_ACTIVE: 'SLIDER_ACTIVE' as const,
  SLIDER_INACTIVE: 'SLIDER_INACTIVE' as const,
  DROPDOWN_OPEN: 'DROPDOWN_OPEN' as const,
  DROPDOWN_CLOSE: 'DROPDOWN_CLOSE' as const,
  MOUSE_CLICK: 'MOUSE_CLICK' as const,
  MOUSE_SECOND_CLICK: 'MOUSE_SECOND_CLICK' as const,
  MOUSE_DOWN: 'MOUSE_DOWN' as const,
  MOUSE_UP: 'MOUSE_UP' as const,
  MOUSE_OVER: 'MOUSE_OVER' as const,
  MOUSE_OUT: 'MOUSE_OUT' as const,
  MOUSE_MOVE: 'MOUSE_MOVE' as const,
  MOUSE_MOVE_IN_VIEWPORT: 'MOUSE_MOVE_IN_VIEWPORT' as const,
  SCROLL_INTO_VIEW: 'SCROLL_INTO_VIEW' as const,
  SCROLL_OUT_OF_VIEW: 'SCROLL_OUT_OF_VIEW' as const,
  SCROLLING_IN_VIEW: 'SCROLLING_IN_VIEW' as const,
  ECOMMERCE_CART_OPEN: 'ECOMMERCE_CART_OPEN' as const,
  ECOMMERCE_CART_CLOSE: 'ECOMMERCE_CART_CLOSE' as const,
  PAGE_START: 'PAGE_START' as const,
  PAGE_FINISH: 'PAGE_FINISH' as const,
  PAGE_SCROLL_UP: 'PAGE_SCROLL_UP' as const,
  PAGE_SCROLL_DOWN: 'PAGE_SCROLL_DOWN' as const,
  PAGE_SCROLL: 'PAGE_SCROLL' as const,
} as const;

/**
 * Event Config Enums
 */

export const EventAppliesTo = {
  ELEMENT: 'ELEMENT' as const,
  CLASS: 'CLASS' as const,
  PAGE: 'PAGE' as const,
} as const;

export const EventBasedOn = {
  ELEMENT: 'ELEMENT' as const,
  VIEWPORT: 'VIEWPORT' as const,
} as const;

export const EventContinuousMouseAxes = {
  X_AXIS: 'X_AXIS' as const,
  Y_AXIS: 'Y_AXIS' as const,
} as const;

export const EventLimitAffectedElements = {
  CHILDREN: 'CHILDREN' as const,
  SIBLINGS: 'SIBLINGS' as const,
  IMMEDIATE_CHILDREN: 'IMMEDIATE_CHILDREN' as const,
} as const;

/**
 * Quick Effect Enums
 */
export const QuickEffectIds = {
  FADE_EFFECT: 'FADE_EFFECT' as const,
  SLIDE_EFFECT: 'SLIDE_EFFECT' as const,
  GROW_EFFECT: 'GROW_EFFECT' as const,
  SHRINK_EFFECT: 'SHRINK_EFFECT' as const,
  SPIN_EFFECT: 'SPIN_EFFECT' as const,
  FLY_EFFECT: 'FLY_EFFECT' as const,
  POP_EFFECT: 'POP_EFFECT' as const,
  FLIP_EFFECT: 'FLIP_EFFECT' as const,
  JIGGLE_EFFECT: 'JIGGLE_EFFECT' as const,
  PULSE_EFFECT: 'PULSE_EFFECT' as const,
  DROP_EFFECT: 'DROP_EFFECT' as const,
  BLINK_EFFECT: 'BLINK_EFFECT' as const,
  BOUNCE_EFFECT: 'BOUNCE_EFFECT' as const,
  FLIP_LEFT_TO_RIGHT_EFFECT: 'FLIP_LEFT_TO_RIGHT_EFFECT' as const,
  FLIP_RIGHT_TO_LEFT_EFFECT: 'FLIP_RIGHT_TO_LEFT_EFFECT' as const,
  RUBBER_BAND_EFFECT: 'RUBBER_BAND_EFFECT' as const,
  JELLO_EFFECT: 'JELLO_EFFECT' as const,
  GROW_BIG_EFFECT: 'GROW_BIG_EFFECT' as const,
  SHRINK_BIG_EFFECT: 'SHRINK_BIG_EFFECT' as const,
  PLUGIN_LOTTIE_EFFECT: 'PLUGIN_LOTTIE_EFFECT' as const,
} as const;

/**
 * Quick Effect Direction Enums
 */
export const QuickEffectDirectionConsts = {
  LEFT: 'LEFT' as const,
  RIGHT: 'RIGHT' as const,
  BOTTOM: 'BOTTOM' as const,
  TOP: 'TOP' as const,
  BOTTOM_LEFT: 'BOTTOM_LEFT' as const,
  BOTTOM_RIGHT: 'BOTTOM_RIGHT' as const,
  TOP_RIGHT: 'TOP_RIGHT' as const,
  TOP_LEFT: 'TOP_LEFT' as const,
  CLOCKWISE: 'CLOCKWISE' as const,
  COUNTER_CLOCKWISE: 'COUNTER_CLOCKWISE' as const,
} as const;
