/* eslint-env browser */
import {IX2BrowserSupport} from '@packages/systems/ix2/shared';
import {IX2EngineConstants} from '@packages/systems/ix2/shared-constants';
const {ELEMENT_MATCHES} = IX2BrowserSupport;
const {IX2_ID_DELIMITER, HTML_ELEMENT, PLAIN_OBJECT, WF_PAGE} =
  IX2EngineConstants;

export function setStyle(element: HTMLElement, prop: string, value: string) {
  // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
  element.style[prop] = value;
}

export function getStyle(element: HTMLElement, prop: string) {
  if (prop.startsWith('--')) {
    return window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(prop);
  }

  if (element.style instanceof CSSStyleDeclaration) {
    // @ts-expect-error - TS7015 - Element implicitly has an 'any' type because index expression is not of type 'number'.
    return element.style[prop];
  }
}

export function getProperty(element: HTMLElement, prop: string) {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'HTMLElement'.
  return element[prop];
}

export function matchSelector(
  selector: string
): (arg1: HTMLElement) => boolean {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'HTMLElement'.
  return (element: HTMLElement) => element[ELEMENT_MATCHES](selector);
}

export function getQuerySelector({
  id,
  selector,
}: {
  id: null | undefined | string;
  selector: string;
}) {
  if (id) {
    let nodeId = id;
    if (id.indexOf(IX2_ID_DELIMITER) !== -1) {
      const pair = id.split(IX2_ID_DELIMITER);
      const pageId = pair[0];
      // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
      nodeId = pair[1];
      // Short circuit query if we're on the wrong page
      if (pageId !== document.documentElement.getAttribute(WF_PAGE)) {
        return null;
      }
    }
    return `[data-w-id="${nodeId}"], [data-w-id^="${nodeId}_instance"]`;
  }
  return selector;
}

export function getValidDocument(pageId?: null | string) {
  if (
    pageId == null ||
    pageId === document.documentElement.getAttribute(WF_PAGE)
  ) {
    return document;
  }
  return null;
}

export function queryDocument(
  baseSelector: string,
  descendantSelector?: null | string
) {
  return Array.prototype.slice.call(
    document.querySelectorAll(
      descendantSelector
        ? baseSelector + ' ' + descendantSelector
        : baseSelector
    )
  );
}

export function elementContains(
  parent: HTMLElement,
  child: HTMLElement
): boolean {
  return parent.contains(child);
}

export function isSiblingNode(a: HTMLElement, b: HTMLElement): boolean {
  return a !== b && a.parentNode === b.parentNode;
}

export function getChildElements(
  // @ts-expect-error - TS2315 - Type 'NodeList' is not generic.
  sourceElements: Array<HTMLElement> | NodeList<HTMLElement>
) {
  const childElements: Array<HTMLElement | any> = [];

  for (let i = 0, {length} = sourceElements || []; i < length; i++) {
    const {children} = sourceElements[i];
    const {length: childCount} = children;
    if (!childCount) {
      continue;
    }
    for (let j = 0; j < childCount; j++) {
      childElements.push(children[j]);
    }
  }
  return childElements;
}

export function getSiblingElements(sourceElements = []) {
  const elements: Array<any> = [];
  const parentCache: Array<any> = [];
  for (let i = 0, {length} = sourceElements; i < length; i++) {
    // @ts-expect-error - TS2339 - Property 'parentNode' does not exist on type 'undefined'.
    const {parentNode} = sourceElements[i];
    if (!parentNode || !parentNode.children || !parentNode.children.length) {
      continue;
    }
    if (parentCache.indexOf(parentNode) !== -1) {
      continue;
    }
    parentCache.push(parentNode);
    let el = parentNode.firstElementChild;
    while (el != null) {
      // @ts-expect-error - TS2345 - Argument of type 'any' is not assignable to parameter of type 'never'.
      if (sourceElements.indexOf(el) === -1) {
        elements.push(el);
      }
      el = el.nextElementSibling;
    }
  }
  return elements;
}

// @ts-expect-error - TS2774 - This condition will always return true since this function is always defined. Did you mean to call it instead?
export const getClosestElement = Element.prototype.closest
  ? (element: HTMLElement, selector: string) => {
      if (!document.documentElement.contains(element)) {
        return null;
      }

      return element.closest(selector) as HTMLElement | null | undefined;
    }
  : (element: HTMLElement, selector: string) => {
      if (!document.documentElement.contains(element)) {
        return null;
      }

      let el = element;

      do {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'HTMLElement'. | TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'HTMLElement'.
        if (el[ELEMENT_MATCHES] && el[ELEMENT_MATCHES](selector)) {
          return el;
        }
        // @ts-expect-error - TS2322 - Type 'ParentNode | null' is not assignable to type 'HTMLElement'.
        el = el.parentNode;
      } while (el != null);
      return null;
    };

export function getRefType(ref: any) {
  if (ref != null && typeof ref == 'object') {
    return ref instanceof Element ? HTML_ELEMENT : PLAIN_OBJECT;
  }
  return null;
}
